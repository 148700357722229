import Image from "next/legacy/image";

export default function CTA(cta) {
    return (
      cta.cta.callToAction && (
            <div id="call-to-action-container" className={`container-fluid ${cta.cta.callToAction.type}`}>
                {
                    cta.cta.callToAction.backgroundImage && (
                    <div id='call-to-action-background-image'>
                        <Image
                        src={`${cta.cta.callToAction.backgroundImage.sourceUrl}`}
                        layout='fill'
                        />
                    </div>
                    )
                }
                <div id="call-to-action-content" className="container">
                    <div>
                        <div>
                            <div dangerouslySetInnerHTML={{__html: cta.cta.callToAction.content}}></div>
                        </div>
                        <div>
                            {
                                cta.cta.callToAction.buttons && cta.cta.callToAction.buttons.length > 0 && cta.cta.callToAction.buttons.map((item, index) => (
                                    <div key={index} dangerouslySetInnerHTML={{__html: item.button}}></div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
      )
    );
  }